* {
  padding: 0;
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
:root {
  --text-color: #13263e;
}

*::-webkit-scrollbar {
  display: none;
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background-image: url("../public/images/mountains.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
}

.landing-page {
  min-height: 100%;
  min-width: 100%;
  background-size: contain;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #080710; */
}

.intro {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.main-card-root {
  padding-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-card-root-left-pane {
  display: flex;
  align-items: center;
  padding: 0.5em;
  gap: 2px;
  flex-direction: column;
}
.name-pic {
  display: flex;
  align-items: center;
  flex-direction: column;
}
li {
  color: aliceblue;
}

.main-card-root-right-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 20px;
  height: 290px;
}
.contact-info {
  margin-top: auto;
  display: flex;
  gap: 10px;
}

.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  z-index: -999;
}

.titles {
  color: #173d4f;
  margin-bottom: 4;
  font-weight: bolder;
  font-family: "Tilt Warp", "cursive";
}

.text {
  color: var(--text-color);
  font-family: "Roboto Mono";
  line-height: 1.4;
}
.links {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.project-list {
  overflow: scroll;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.project-list::-webkit-scrollbar {
  display: none;
}

.nav-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.education-timeline-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}
.education-timeline-container::-webkit-scrollbar {
  display: none;
}
.main-card-root-right-pane-main-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
}
.main-card-root-right-pane-main-text::-webkit-scrollbar {
  display: none;
}
.header {
  color: white;
  font-size: 20px;
  font-weight: bolder;
  font-family: "Tilt Warp", "cursive";
}
.header-second {
  color: white;
  font-size: 16px;
  font-weight: bolder;
  font-family: "Tilt Warp", "cursive";
}

/* Mobile */
@media screen and (min-width: 425px) {
}

/* Tablet */
@media screen and (min-width: 620px) {
}
@media screen and (min-width: 768px) {
}

/* Desktop */
@media screen and (min-width: 1024px) {
  * {
    font-size: 100%;
  }
  .header {
    font-size: 56px;
  }
  .header-second {
    font-size: 32px;
  }

  .text {
    font-size: 20px;
  }
  .intro {
  }
  .main-card {
    padding: 1rem;
    flex: 1;
  }
  .main-card-root {
    flex-direction: row;
    flex: 1;
    max-height: fit-content;
  }
  .main-card-root-left-pane {
    flex: 0.5;
  }
  .main-card-root-right-pane {
    flex: 2;
    height: 500px;
  }
  .education-timeline-container {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .nav-link-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .links {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .titles {
    font-size: 52px;
  }

  .typewriter h1 {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    border-right: 0.15em solid var(--text-color);
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    /* letter-spacing: 1em; */
    animation: typing 1.5s steps(32), blink-caret 0.75s step-end infinite;
    animation-fill-mode: forwards;
  }
}
@media screen and (min-width: 1440px) {
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--text-color);
  }
}
